import { supabase } from "../supabaseClient";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "../constants/publicroutes";
import { ToastQueue } from "@react-spectrum/toast";
import useVibration from "../hooks/useVibration";

function useSupabaseLogout() {
  const navigate = useNavigate();
  const { vibrate } = useVibration();
  const [validationState, setValidationState] = useState("");

  async function logout() {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        setValidationState({
          name: "Information de validation",
          message: "Échec de la déconnexion. Réessaye, si le problème persiste, contacte-nous pour une assistance.",
        });
        return;
      }
      navigate(PUBLIC_ROUTES.index);
      ToastQueue.positive("Déconnecté", { timeout: 5000 });
      vibrate();
    } catch (error) {
      setValidationState({
        name: "Information de validation",
        message: "Échec de la déconnexion. Réessaye, si le problème persiste, contacte-nous pour une assistance.",
      });
      return;
    }
  }

  return { logout, validationState };
}

export default useSupabaseLogout;
