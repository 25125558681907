import { useState } from "react";

const useSecurityEmailVerification = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState("");
  const [showMessageOutsideForm, setShowMessageOutsideForm] = useState(false);

  const handleErrorMessage = (errorMessage) => {
    setLoading(false);
    setMessageVariant("notice");
    setShowMessageOutsideForm(false);
    setMessage(errorMessage);
  };

  const securityEmailVerification = async (email) => {
    if (!email) {
      handleErrorMessage("Veuillez fournir une adresse email valide.");
      return false;
    }

    const encodedEmail = encodeURIComponent(email);
    const apiUrl = `https://checkmailipqualityscore.bluecornflakes.workers.dev/?email=${encodedEmail}`;

    try {
      setLoading(true);
      const response = await fetch(apiUrl);
      const data = await response.json();

      setLoading(false);

      if (data.success) {
        setMessageVariant("positive");
        setMessage(data.message || "Vérification réussie !");
        return true;
      } else {
        handleErrorMessage(data.message || "La vérification a échoué.");
        return false;
      }
    } catch (error) {
      handleErrorMessage(`Erreur technique lors de la vérification de l'email : ${error.message}`);
      return false;
    }
  };

  return {
    loading,
    message,
    messageVariant,
    showMessageOutsideForm,
    securityEmailVerification,
  };
};

export default useSecurityEmailVerification;
