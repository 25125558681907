const supabaseKeyTranslation = Object.freeze([
  { id: "email_confirmed_at", translation: "E-mail confirmé le" },
  { id: "last_sign_in_at", translation: "Dernière connexion" },
  { id: "created_at", translation: "Date d'inscription" },
  { id: "updated_at", translation: "Dernière mise à jour" },
  { id: "phone", translation: "Numéro de téléphone" },
  { id: "confirmed_at", translation: "Vérifié le" },
  { id: "accepted_at", translation: "Conditions acceptées le" },
  { id: "is_active", translation: "Compte en cours d'utilisation" },
  { id: "receive_messages", translation: "Recevoir des messages" },
  { id: "receive_visits", translation: "Recevoir des visites" },
  { id: "receive_flash", translation: "Recevoir des flashes" },
  { id: "banned_until", translation: "Statut Bannissement jusqu'à" },
  { id: "username", translation: "Nom d'utilisateur" },
  { id: "avatar_url", translation: "Photo de profil" },
  { id: "birth_date", translation: "Date de naissance" },
  { id: "city", translation: "Ville" },
  { id: "height", translation: "Taille en cm" },
  { id: "has_children", translation: "A des enfants" },
  { id: "is_non_smoker", translation: "Non-fumeur" },
  { id: "is_non_drinker", translation: "Ne consomme pas d'alcool" },
  { id: "has_disability", translation: "Handicap" },
  { id: "is_not_vaccinated", translation: "Non vacciné" },
  { id: "is_spiritual", translation: "Spiritualité" },
  { id: "gender", translation: "Genre" },
  { id: "sexual_orientation", translation: "Orientation sexuelle" },
  { id: "zodiac", translation: "Signe astrologique" },
  { id: "relationship_duration", translation: "Durée de relation recherchée" },
  { id: "diet", translation: "Régime alimentaire" },
  { id: "communication_style", translation: "Style de communication" },
  { id: "sport", translation: "Activité sportive" },
  { id: "social_media", translation: "Utilisation des réseaux sociaux" },
  { id: "sleeping_habits", translation: "Habitudes de sommeil" },
  { id: "country", translation: "Pays" },
  { id: "user_pets", translation: "Animaux de compagnie" },
  { id: "user_wellness", translation: "Bien-être" },
  { id: "user_habitat", translation: "Habitat" },
  { id: "user_badges", translation: "Badges" },
  { id: "profile_completion", translation: "Progression du profil" },
  { id: "continents", translation: "Continent" },
]);

const sectionsUserProfil = [
  { translation: "Informations d'authentification", id: "auth_user_info" },
  { translation: "Conversations", id: "user_conversations_info" },
  { translation: "Conditions d'utilisation acceptées", id: "user_terms_info" },
  { translation: "Statut du membre", id: "user_status_info" },
  { translation: "Préférences de notifications", id: "user_notification_preferences_info" },
  { translation: "Localisation géographique", id: "user_geocode_info" },
  { translation: "Profil utilisateur", id: "user_profile_info" },
  { translation: "Critères de recherche", id: "user_search_criteria_info" },
  { translation: "Cercles", id: "user_circle_info" },
  { translation: "Utilisateurs bloqués", id: "user_blocked_info" },
  { translation: "Flashes reçus", id: "user_flashes_info" },
  { translation: "Mentions 'J'aime'", id: "user_likes_info" },
  { translation: "Visites du profil", id: "user_visits_info" },
];

const communicationStyleOptions = Object.freeze([
  { id: "bigTimeTexter", name: "Textos" },
  { id: "phoneCaller", name: "Appel téléphonique" },
  { id: "badTexter", name: "Peu de textos" },
  { id: "videoChatter", name: "Vidéo" },
  { id: "betterInPerson", name: "En personne" },
]);

const sportOptions = Object.freeze([
  { id: "everyDay", name: "Tous les jours" },
  { id: "often", name: "Souvent" },
  { id: "sometimes", name: "Parfois" },
  { id: "gymRat", name: "Accro" },
  { id: "occasionally", name: "De temps en temps" },
  { id: "never", name: "Jamais" },
]);

const socialMediaOptions = Object.freeze([
  { id: "influencerStatus", name: "Influenceur" },
  { id: "sociallyActive", name: "Oui je suis actif" },
  { id: "offTheGrid", name: "OFF" },
  { id: "passiveScroller", name: "Modérément" },
]);

const sleepingHabitsOptions = Object.freeze([
  { id: "earlyBird", name: "Lève-tôt" },
  { id: "nightOwl", name: "Oiseau de nuit" },
  { id: "itVaries", name: "Ça varie" },
]);

const genderOptions = Object.freeze([
  { id: "female", name: "Une femme" },
  { id: "male", name: "Un homme" },
  { id: "beyondBinary", name: "non-binaire" },
]);

const sexualOrientationOptions = Object.freeze([
  { id: "straight", name: "Hétéro" },
  { id: "bisexual", name: "Bi" },
  { id: "gay", name: "Gay" },
  { id: "lesbian", name: "Lesbienne" },
  { id: "asexual", name: "Asexuel" },
  { id: "questioning", name: "En questionnement" },
]);

const dietaryPreferences = Object.freeze({
  omnivore: "Omnivor",
  vegetarian: "Végétarien",
  flexi: "Flexitarien",
  pescatarian: "Pesco",
  vegan: "Végétalien",
  raw: "Crudivor",
});

const termPreferences = Object.freeze({
  longTerm: "Une relation durable",
  shortTerm: "Une courte aventure",
  popUp: "Une découverte",
});

const zodiac = Object.freeze({
  capricorn: "Capricorne",
  aquarius: "Verseau",
  pisces: "Poissons",
  aries: "Bélier",
  taurus: "Taureau",
  gemini: "Gémeaux",
  cancer: "Cancer",
  leo: "Lion",
  virgo: "Vierge",
  libra: "Balance",
  scorpio: "Scorpion",
  sagittarius: "Sagittaire",
});

const personalityTraits = Object.freeze({
  ambition: "L'ambition",
  autonomy: "L'autonomie",
  adventure: "L'aventure",
  calm: "Le calme",
  creativity: "La créativité",
  dedication: "Le dévouement",
  dynamism: "Le dynamisme",
  empathy: "L'empathie",
  extraversion: "L'extraversion",
  honesty: "L'honnêteté",
  imagination: "L'imagination",
  intelligence: "L'intelligence",
  observation: "L'observation",
  order: "L'ordre",
  openMindedness: "L'ouverture d'esprit",
  patience: "La patience",
  perseverance: "La persévérance",
  prudence: "La prudence",
  senseOfResponsibility: "Le sens des responsabilités",
  sensitivity: "La sensibilité",
  sociability: "La sociabilité",
  timidity: "La timidité",
  other: "Autre",
});

const pets = [
  {
    title: "Âne",
    key: "donkey",
    emoji: "🐴",
    emojiAccessibleTitle: "Emoji d'un âne",
  },
  {
    title: "Autres",
    key: "other",
    emoji: "🔍",
    emojiAccessibleTitle: "Emoji d'une loupe",
  },
  {
    title: "Brebis",
    key: "sheep",
    emoji: "🐑",
    emojiAccessibleTitle: "Emoji d'une brebis",
  },
  {
    title: "Chat",
    key: "cat",
    emoji: "🐱",
    emojiAccessibleTitle: "Emoji d'un chat",
  },
  {
    title: "Cheval",
    key: "horse",
    emoji: "🐴",
    emojiAccessibleTitle: "Emoji d'un cheval",
  },
  {
    title: "Chèvre",
    key: "goat",
    emoji: "🐐",
    emojiAccessibleTitle: "Emoji d'une chèvre",
  },
  {
    title: "Chien",
    key: "dog",
    emoji: "🐶",
    emojiAccessibleTitle: "Emoji d'un chien",
  },
  {
    title: "Cochon d'Inde",
    key: "guineaPig",
    emoji: "🐹",
    emojiAccessibleTitle: "Emoji d'un cochon d'Inde",
  },
  {
    title: "Furet",
    key: "ferret",
    emoji: "🦦",
    emojiAccessibleTitle: "Emoji d'une loutre",
  },
  {
    title: "Hamster",
    key: "hamster",
    emoji: "🐹",
    emojiAccessibleTitle: "Emoji d'un hamster",
  },
  {
    title: "Lapin",
    key: "rabbit",
    emoji: "🐰",
    emojiAccessibleTitle: "Emoji d'un lapin",
  },
  {
    title: "Oiseau",
    key: "bird",
    emoji: "🐦",
    emojiAccessibleTitle: "Emoji d'un oiseau",
  },
  {
    title: "Perroquet",
    key: "parrot",
    emoji: "🦜",
    emojiAccessibleTitle: "Emoji d'un perroquet",
  },
  {
    title: "Perruche",
    key: "budgie",
    emoji: "🐦",
    emojiAccessibleTitle: "Emoji d'une perruche",
  },
  {
    title: "Poisson",
    key: "fish",
    emoji: "🐟",
    emojiAccessibleTitle: "Emoji d'un poisson",
  },
  {
    title: "Poney",
    key: "pony",
    emoji: "🐴",
    emojiAccessibleTitle: "Emoji d'un poney",
  },
  {
    title: "Poule",
    key: "chicken",
    emoji: "🐔",
    emojiAccessibleTitle: "Emoji d'une poule",
  },
  {
    title: "Rat",
    key: "rat",
    emoji: "🐀",
    emojiAccessibleTitle: "Emoji d'un rat",
  },
  {
    title: "Reptiles",
    key: "reptiles",
    emoji: "🦎",
    emojiAccessibleTitle: "Emoji d'un lézard",
  },
  {
    title: "Souris",
    key: "mouse",
    emoji: "🐭",
    emojiAccessibleTitle: "Emoji d'une souris",
  },
  {
    title: "Tortue",
    key: "turtle",
    emoji: "🐢",
    emojiAccessibleTitle: "Emoji d'une tortue",
  },
];

const livingPreferences = [
  {
    title: "À la campagne",
    key: "countryside",
    emoji: "🌳",
    emojiAccessibleTitle: "Emoji d'un arbre",
  },
  {
    title: "Un Ecolieu",
    key: "ecoplace",
    emoji: "🪷",
    emojiAccessibleTitle: "Emoji d'une tente",
  },
  {
    title: "Un bateau",
    key: "boat",
    emoji: "⛵",
    emojiAccessibleTitle: "Emoji d'une tente",
  },
  {
    title: "Woofing",
    key: "woofing",
    emoji: "🧺",
    emojiAccessibleTitle: "Emoji d'un panier de récolte",
  },
  {
    title: "Une Tiny House",
    key: "tinyhouse",
    emoji: "🏕️",
    emojiAccessibleTitle: "Emoji d'un panier d'une tente",
  },
  {
    title: "Un Van",
    key: "van",
    emoji: "🚐",
    emojiAccessibleTitle: "Emoji d'un panier d'un van",
  },
  {
    title: "À la mer",
    key: "seaside",
    emoji: "🌊",
    emojiAccessibleTitle: "Emoji d'une vague",
  },
  {
    title: "À la montagne",
    key: "mountains",
    emoji: "⛰️",
    emojiAccessibleTitle: "Emoji d'une montagne",
  },
  {
    title: "À la ville",
    key: "city",
    emoji: "🏙️",
    emojiAccessibleTitle: "Emoji d'une ville",
  },
  {
    title: "Un Appartement",
    key: "apartment",
    emoji: "🏢",
    emojiAccessibleTitle: "Emoji d'un immeuble",
  },
  {
    title: "Une Maison",
    key: "house",
    emoji: "🏠",
    emojiAccessibleTitle: "Emoji d'une maison",
  },
  {
    title: "Appartement accessible",
    key: "accessibleApartment",
    emoji: "♿",
    emojiAccessibleTitle: "Emoji d'un fauteuil roulant",
  },
  {
    title: "Maison accessible",
    key: "accessibleHouse",
    emoji: "🏠♿",
    emojiAccessibleTitle: "Emoji d'une maison avec un symbole d'accessibilité",
  },
  {
    title: "En Résidence avec assistance",
    key: "assistedLivingFacility",
    emoji: "🏥",
    emojiAccessibleTitle: "Emoji d'un hôpital",
  },
];

const naturalWellness = Object.freeze([
  {
    title: "Acupuncture",
    key: "acupuncture",
    emoji: "📍",
    emojiAccessibleTitle: "Emoji d'une epingle",
  },
  {
    title: "Alimentation vivante",
    key: "rawFood",
    emoji: "🥗",
    emojiAccessibleTitle: "Emoji d'une salade",
  },
  {
    title: "Apithérapie",
    key: "apitherapy",
    emoji: "🐝",
    emojiAccessibleTitle: "Emoji d'une abeille",
  },
  {
    title: "Aromathérapie",
    key: "aromatherapy",
    emoji: "🌸",
    emojiAccessibleTitle: "Emoji d'une fleur",
  },
  {
    title: "Ayurveda",
    key: "ayurveda",
    emoji: "🧘",
    emojiAccessibleTitle: "Emoji d'une personne en méditation",
  },
  {
    title: "Baubiologie",
    key: "baubiology",
    emoji: "🏡",
    emojiAccessibleTitle: "Emoji d'une maison avec un jardin",
  },
  {
    title: "Beauté bio",
    key: "organicBeauty",
    emoji: "🌿",
    emojiAccessibleTitle: "Emoji de feuilles",
  },
  {
    title: "Bio énergie",
    key: "bioEnergy",
    emoji: "⚡",
    emojiAccessibleTitle: "Emoji d'un éclair",
  },
  {
    title: "Coaching",
    key: "coaching",
    emoji: "👩‍🏫",
    emojiAccessibleTitle: "Emoji d'une professeure",
  },
  {
    title: "Commerce équitable",
    key: "fairTrade",
    emoji: "🌍",
    emojiAccessibleTitle: "Emoji d'un globe terrestre",
  },
  {
    title: "Décroissance",
    key: "degrowth",
    emoji: "📉",
    emojiAccessibleTitle: "Emoji d'un graphique en baisse",
  },
  {
    title: "Développement personnel",
    key: "personalDevelopment",
    emoji: "📈",
    emojiAccessibleTitle: "Emoji d'un graphique en hausse",
  },
  {
    title: "Dien Cham",
    key: "dienCham",
    emoji: "🤲",
    emojiAccessibleTitle: "Emoji de mains ouvertes",
  },
  {
    title: "Diététique",
    key: "dietetics",
    emoji: "🍎",
    emojiAccessibleTitle: "Emoji d'une pomme",
  },
  {
    title: "Émissions TV bien-être",
    key: "wellnessTVShows",
    emoji: "📺",
    emojiAccessibleTitle: "Emoji d'une télévision",
  },
  {
    title: "Énergies renouvelables",
    key: "renewableEnergy",
    emoji: "♻️",
    emojiAccessibleTitle: "Emoji de recyclage",
  },
  {
    title: "Ésotérisme",
    key: "esotericism",
    emoji: "🔮",
    emojiAccessibleTitle: "Emoji d'une boule de cristal",
  },
  {
    title: "Feng shui",
    key: "fengShui",
    emoji: "🧭",
    emojiAccessibleTitle: "Emoji d'une boussole",
  },
  {
    title: "Gemmothérapie",
    key: "gemmotherapy",
    emoji: "💎",
    emojiAccessibleTitle: "Emoji d'un diamant",
  },
  {
    title: "Géobiologie",
    key: "geobiology",
    emoji: "🌏",
    emojiAccessibleTitle: "Emoji d'un globe terrestre",
  },
  {
    title: "Habitat écologique",
    key: "ecologicalHabitat",
    emoji: "🏡",
    emojiAccessibleTitle: "Emoji d'une maison avec un jardin",
  },
  {
    title: "Homéopathie",
    key: "homeopathy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Hydrolathérapie",
    key: "hydrosolTherapy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Hygiène bio",
    key: "organicHygiene",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Kinésiologie",
    key: "kinesiology",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Lithothérapie",
    key: "lithotherapy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Luminothérapie",
    key: "lightTherapy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Magnétisme",
    key: "magnetism",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Marche nordique",
    key: "nordicWalking",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Massage bien-être",
    key: "wellnessMassage",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Médecine chinoise",
    key: "chineseMedicine",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Médecine des ventouses",
    key: "cuppingTherapy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Méditation",
    key: "meditation",
    emoji: "🧘", // Ajout de l'emoji correspondant
    emojiAccessibleTitle: "Emoji d'une personne en méditation", // Titre accessible de l'emoji
  },
  {
    title: "Musicothérapie",
    key: "musicTherapy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Naturisme",
    key: "naturism",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Naturopathie",
    key: "naturopathy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Numérologie",
    key: "numerology",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Phytothérapie",
    key: "phytotherapy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Qi gong",
    key: "qiGong",
    emoji: "🏮", // Ajout de l'emoji correspondant
    emojiAccessibleTitle: "Emoji d'une lanterne japonaise", // Titre accessible de l'emoji
  },
  {
    title: "Recyclage",
    key: "recycling",
    emoji: "♻️", // Ajout de l'emoji correspondant
    emojiAccessibleTitle: "Emoji de recyclage", // Titre accessible de l'emoji
  },
  {
    title: "Réflexologie",
    key: "reflexology",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Reiki",
    key: "reiki",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Salons bien-être",
    key: "wellnessSalons",
    emoji: "💆", // Ajout de l'emoji correspondant
    emojiAccessibleTitle: "Emoji d'une personne recevant un massage", // Titre accessible de l'emoji
  },
  {
    title: "Shiatsu",
    key: "shiatsu",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Sophrologie",
    key: "sophrology",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Spiritualité",
    key: "spirituality",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Taï-chi",
    key: "taiChi",
    emoji: "🌊", // Ajout de l'emoji correspondant
    emojiAccessibleTitle: "Emoji d'une vague", // Titre accessible de l'emoji
  },
  {
    title: "Tantrisme",
    key: "tantrism",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Thalassothérapie",
    key: "thalassotherapy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Thérapie énergétique",
    key: "energyTherapy",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Végétarisme",
    key: "vegetarianism",
    emoji: "", // Ajoutez ici l'emoji correspondant si nécessaire
    emojiAccessibleTitle: "", // Ajoutez ici le titre accessible de l'emoji si nécessaire
  },
  {
    title: "Yoga",
    key: "yoga",
    emoji: "🧘",
    emojiAccessibleTitle: "Emoji d'une personne en méditation",
  },
]);

export {
  supabaseKeyTranslation,
  sectionsUserProfil,
  genderOptions,
  dietaryPreferences,
  zodiac,
  termPreferences,
  sexualOrientationOptions,
  sleepingHabitsOptions,
  socialMediaOptions,
  sportOptions,
  communicationStyleOptions,
  livingPreferences,
  naturalWellness,
  personalityTraits,
  pets,
};
