import { lazy, Suspense } from "react";
import {
  Well,
  Button,
  DialogTrigger,
  Tooltip,
  TooltipTrigger,
  Flex,
  Heading,
  Content,
  ContextualHelp,
  View,
  Text,
  Footer,
} from "@adobe/react-spectrum";

import TramFlashIcon from "./TramFlashIcon";
import Follow from "@spectrum-icons/workflow/Heart";
import Loading from "./Loading";

// Chargement paresseux du composant de dialogue
const LazyTipsDialog = lazy(() => import("./TipsDialog"));

function PublicSubFooter() {
  const currentYear = new Date().getFullYear();

  // Fonction pour afficher une section "Well"
  const renderWell = (texte) => <Well marginY="size-100">{texte}</Well>;

  return (
    <View marginY="size-100" width={{ base: "100%", L: "25%" }}>
      <Flex direction="column" wrap marginY="size-100" alignItems="start" justifyContent="center" gap="size-250">
        <Flex alignItems="center" justifyContent="center">
          {/* Icône personnalisée avec description */}
          <TramFlashIcon aria-label="Icône de chemin intérieur et de parcours" />
          <Heading margin="size-10" level={3}>
            Tramway
          </Heading>

          {/* Aide contextuelle */}
          <ContextualHelp variant="info">
            <Heading>
              Tramway • courant alternatif <span>&#x00AE;</span>
            </Heading>
            <Content>
              {renderWell(
                `Tramway est une marque française déposée à l'INPI sous le N° 5027214 • 2023-${currentYear} tous droits réservés.`,
              )}
              {renderWell(
                `Tramway est une plateforme indépendante créée par Bluecornflakes : entreprise non-diffusible, Article A123-96 du Code de Commerce.`,
              )}
              {renderWell(
                "Les membres doivent être légalement majeurs et responsables, conformément à la loi. Utilisez Tramway de manière légale et positive.",
              )}
            </Content>

            <Footer>
              <Text>
                <span lang="en">
                  Bluecornflakes • digital native <span title="emoji : pieds nus">👣</span>
                </span>
              </Text>
            </Footer>
          </ContextualHelp>
        </Flex>

        <Text>
          Petite plateforme de rencontre accessible mais bien &quot;Roots&quot;. Nous célébrons les alternatives et les
          jus de légumes
          {` • 2023-${currentYear} tous droits réservés.`}
        </Text>

        {/* Bouton pour ouvrir le dialogue de dons */}
        <DialogTrigger type="tray" isDismissable>
          <TooltipTrigger delay={0}>
            <Button variant="secondary" aria-label="Faire un don">
              <Follow color="negative" /> <Text>By Bluecornflakes</Text>
            </Button>
            <Tooltip>Faire un don sur notre blog KOFI</Tooltip>
          </TooltipTrigger>

          {/* Chargement paresseux du dialogue */}
          <Suspense fallback={<Loading />}>
            <LazyTipsDialog />
          </Suspense>
        </DialogTrigger>
      </Flex>
    </View>
  );
}

export default PublicSubFooter;
